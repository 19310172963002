import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { makeMoney } from '../utilities.js'

const ListingViewGrid = ({ results }) => {
  return (<div className="row">
    { results.map((node)=>{ 
      return (
        <Link to={'/details/'+node.fields.slug+'/'} key={'grid-'+node.fields.slug} className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-3 mb-5 px-3 text-decoration-none grid-view-cell">
          {(()=>{
            if( node.fields.imageSharp === null ){
              return <StaticImage width={520} aspectRatio={1.25} objectFit="contain" src={'../../data/images/_no_console.png'} imgStyle={{ objectFit: 'contain' }} alt={'No image, try rebuilding.'} />
            }else{
              return <GatsbyImage image={node.fields.imageSharp.large} alt={node.frontmatter.name} imgStyle={{ objectFit: 'contain' }} />
            }
          })()}
          <div className="text-center">
            <h4 className="fw-light">{ node.frontmatter.name }</h4>
            <h6 className="fw-light">{ makeMoney(node.fields.query_releasePrice) } released { node.fields.query_releaseDate }</h6>
            <h5>{ makeMoney(node.fields.query_adjustedPrice) } today</h5>
          </div>
      </Link>
      )
    }) }
  </div>)
}
export default ListingViewGrid