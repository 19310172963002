import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { makeMoney } from '../utilities.js'

const ListingViewChart = ({ results }) => {
  // Need the min/max for bar size calculations
  let highestValue = Number.NEGATIVE_INFINITY;
  let originalValues = [];
  let adjustedValues = [];
  results.forEach((result, index)=>{
    highestValue = Math.max( highestValue, result.fields.query_releasePrice, result.fields.query_adjustedPrice )
    originalValues.push(result.fields.query_releasePrice);
    adjustedValues.push(result.fields.query_adjustedPrice);
  })
  // Prepare math for later
  const resultCount = results.length;
  let originalAverage = 0;
  let originalAveragePercentage = 0;
  let adjustedAverage = 0;
  let adjustedAveragePercentage = 0;
  if( resultCount > 1){
    originalAverage = originalValues.reduce((a,b)=>a+b) / results.length;
    originalAveragePercentage = ( originalAverage / highestValue ) * 100
    adjustedAverage = adjustedValues.reduce((a,b)=>a+b) / results.length;
    adjustedAveragePercentage = ( adjustedAverage / highestValue ) * 100
  }

  return (<div className="my-3 mb-5">
    { results.map((node)=>{ 
      const originalPercentage = (node.fields.query_releasePrice / highestValue) * 100;
      const adjustedPercentage = (node.fields.query_adjustedPrice / highestValue) * 100;
      if( node.fields.imageSharp === null ){
        node.fields.imageSharp = {};
      }
      return (<Link to={'/details/'+node.fields.slug+'/'} key={'chart-'+node.fields.slug} className="row mx-0 chart-row">
        {(()=>{
          if( node.fields.imageSharp === null ){
            return <StaticImage width={64} height={64} objectFit="contain" src={'../../data/images/_no_console.png'} imgStyle={{ objectFit: 'contain' }} alt={'No image, try rebuilding.'} />
          }else{
            return <GatsbyImage className="col" image={node.fields.imageSharp.small} alt={node.frontmatter.name} imgStyle={{ objectFit: 'contain' }} />
          }
        })()}
        
        <div className="chart-wrap">
          <div className="bar-adjusted" style={{width: adjustedPercentage+'%'}}></div>
          <div className="bar-original" style={{width: originalPercentage+'%'}}></div>
          {(()=>{
            if( resultCount > 1 ){
              return <>
                <div className="line-average original" style={{left: originalAveragePercentage+'%'}}></div>
                <div className="line-average adjusted" style={{left: adjustedAveragePercentage+'%'}}></div>
              </>
            }
            return null
          })()}
          <div className="text">
            { node.frontmatter.company } { node.frontmatter.name } <br/>Released { node.fields.query_releaseDate } for {makeMoney(node.fields.query_releasePrice)}, {makeMoney(node.fields.query_adjustedPrice)} today
          </div>
        </div>
      </Link>)
    }) }
    {(()=>{
      if( resultCount > 1 ){
        return <>
          <div className="row mx-0 chart-row">
            <div className="chart-no-image-spacer"></div>
            <div className="chart-wrap average-line">
              <div className="line-average adjusted" style={{left: adjustedAveragePercentage+'%'}}></div>
              {(()=>{
                if( originalAveragePercentage > 50 ){
                  return <>
                    <div className="text-end line-average-label" style={{paddingRight: (100 - originalAveragePercentage)+'%'}}>Average Adjusted Price: <strong>{makeMoney(originalAverage)}</strong>&nbsp;</div>
                  </>
                }
                return null
              })()}
              <div className="line-average original" style={{left: originalAveragePercentage+'%'}}></div>
              {(()=>{
                if( originalAveragePercentage <= 50 ){
                  return <div className="line-average-label" style={{paddingLeft: originalAveragePercentage+'%'}}>&nbsp;Average Original Price: <strong>{makeMoney(originalAverage)}</strong></div>
                }
                return null
              })()}
            </div>
          </div>
          <div className="row mx-0 chart-row">
            <div className="chart-no-image-spacer"></div>
            <div className="chart-wrap average-line">
              {(()=>{
                if( adjustedAveragePercentage > 50 ){
                  return <>
                    <div className="text-end line-average-label" style={{paddingRight: (100 - adjustedAveragePercentage)+'%'}}>Average Adjusted Price: <strong>{makeMoney(adjustedAverage)}</strong>&nbsp;</div>
                  </>
                }
                return null
              })()}
              <div className="line-average adjusted" style={{left: adjustedAveragePercentage+'%'}}></div>
              {(()=>{
                if( adjustedAveragePercentage <= 50 ){
                  return <div className="line-average-label" style={{paddingLeft: adjustedAveragePercentage+'%'}}>&nbsp;Average Adjusted Price: <strong>{makeMoney(adjustedAverage)}</strong></div>
                }
                return null
              })()}
            </div>
          </div>
        </>
      }
      return null
    })()}
  </div>)
}
export default ListingViewChart