import React from "react"
import { graphql } from 'gatsby'

import Layout from '../components/_layout.js';
import Filters from '../components/filters';
import ListingViewGrid from "../components/listing-grid"
import ListingViewChart from "../components/listing-chart"

export default function Home({data, pageContext}) {
  const results = data.allMarkdownRemark.nodes;

  // Stored last listing page so it can be quickly swapped out
  if( typeof localStorage !== 'undefined'){
    localStorage['lastListingPage'] = window.location.href;
  }

  return <Layout>
    <Filters listingPageContext={pageContext} />
    
    <div className="container">
      {pageContext.view === 'Chart' ? <ListingViewChart results={results} />: <ListingViewGrid results={results} /> }
      <ResultsMessage count={data.allMarkdownRemark.totalCount} currentFilters={[pageContext._queryType,pageContext._queryCompany]}/>
    </div>
    {/* <pre style={{color:'red'}}>{ JSON.stringify(data, null, 2)}</pre>
    <pre style={{color:'blue'}}>{ JSON.stringify(pageContext, null, 2)}</pre> */}
  </Layout>
}

function ResultsMessage({ count, currentFilters }){
  let message = 'Something really strange happened and it\'s best to assume there are no hardware releases found.';
  if( currentFilters[0] === "" && currentFilters[1] === ""){
    message = 'There are '+count+' hardware releases currently tracked and adjusted for inflation.';
  }else if(count>1){
    message = 'There are '+count+' hardware releases matching the current filters.';
  }else if(count === 1){
    message = 'There is '+count+' hardware release matching the current filters.';
  }else{
    message = 'There are no hardware releases matching the current filters.';
  }
  return <div className="col-10 offset-1 pt-3 my-3 text-center">{ message }</div>
}

export const query = graphql`
  query (
    $_querySortField: [MarkdownRemarkFieldsEnum] = fields___query_releaseDate, 
    $_querySortDirection: [SortOrderEnum] = DESC,
    $_queryCompany: String! = "//"
    $_queryType: String! = "//"
  ) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/data/hardware/"},
        frontmatter: {
          company: {regex: $_queryCompany },
          type: {regex: $_queryType },
        }
      }
      sort: {fields: $_querySortField, order: $_querySortDirection}
    ) {
      nodes {
        frontmatter {
          image
          name
          company
        }
        fields {
          query_adjustedPrice
          query_releasePrice
          query_releaseDate(fromNow: true)
          slug
          imageSharp {
            large: gatsbyImageData(
              aspectRatio: 1.25
              width: 520
              transformOptions: {fit: CONTAIN}
            )
            small: gatsbyImageData(
              width: 64
              height: 64
              transformOptions: {fit: CONTAIN}
            )
          }
        }
      }
      totalCount
    }
  }
`;